/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./fonts.css"

const Layout = ({ children }) => {
  
  return (
    <div className="bg-red-400 two-tone-default">
      <div className="relative max-w-7xl mx-auto min-h-screen bg-white">
        {children}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
